import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'

const ProgressContainer = styled.div`
  position: relative;
`

const SVGContainer = styled.svg`
  transform: rotate(-90deg);
`

const CircleBackground = styled.circle`
  stroke: ${props => props.theme.palette.neutral.default.one};
`

const CircleProgress = styled.circle`
  transition: stroke-dashoffset 0.5s ease;
  
  &.tier-bronze {
    stroke: ${props => props.theme.palette.product.luxLoyalty.bronze.backgroundShade};
  }

  &.tier-silver {
    stroke: ${props => props.theme.palette.product.luxLoyalty.silver.backgroundShade};
  }

  &.tier-gold {
    stroke: ${props => props.theme.palette.product.luxLoyalty.gold.backgroundShade};
  }

  &.tier-platinum {
    stroke: ${props => props.theme.palette.product.luxLoyalty.platinum.contrast};
  }
`

const ProgressContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface Props {
  progress: number;
  /**
   * @default neutral-four
   */
  tier: App.LuxLoyaltyTier;
}

function LuxLoyaltyAccountTileCircularProgressBar(props: PropsWithChildren<Props>) {
  const { progress, children, tier } = props
  const SIZE = 73
  const circleCoords = Math.round(SIZE / 2)
  const circleRadius = circleCoords - 5
  const circumference = 2 * Math.PI * circleRadius
  const offset = circumference - (progress / 100) * circumference

  return (
    <ProgressContainer style={{ height: rem(SIZE), width: rem(SIZE) }}>
      <SVGContainer width={SIZE} height={SIZE} viewBox="0 0 73 73">
        {/* Background Circle */}
        <CircleBackground
          cx={circleCoords}
          cy={circleCoords}
          r={circleRadius}
          strokeWidth={4}
          fill="none"
        />
        {/* Progress Circle */}
        <CircleProgress
          className={cn(`tier-${tier}`)}
          cx={circleCoords}
          cy={circleCoords}
          r={circleRadius}
          strokeWidth={2}
          fill="none"
          strokeDasharray={`${circumference} ${circumference}`}
          strokeLinecap="round"
          strokeDashoffset={offset}
        />
      </SVGContainer>
      {children && <ProgressContent style={{ height: rem(SIZE), width: rem(SIZE) }}>
        {children}
      </ProgressContent>}
    </ProgressContainer>
  )
}

export default React.memo(LuxLoyaltyAccountTileCircularProgressBar)
